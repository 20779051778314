$(document).on('ready', function () {
    // initialization of slick carousel
    $.HSCore.components.HSSlickCarousel.init('.js-slick-carousel');
    
    // initialization of header
    $.HSCore.components.HSHeader.init($('#header'));
    
    // initialization of chart pies
    var circles = $.HSCore.components.HSChartPie.init('.js-pie');
    var counters = $.HSCore.components.HSCounter.init('.js-counter');
    $('.js-slick-carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        var counters = $.HSCore.components.HSCounter.init('.js-counter');
        var circles = $.HSCore.components.HSChartPie.init('.js-pie');
    });
    
    // initialization of header
    $.HSCore.components.HSHeader.init($('#header'));
    
    $( ".scroll" ).click(function( event ) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 500);
    });
});

$(window).on('load', function () {
    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 767.98,
        hideTimeOut: 0
    });
});